import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Col, Row, Card, CardBody, CardTitle } from "reactstrap"
import DonutChart from "../AllCharts/apex/dountchart"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import BarChart from "../AllCharts/apex/barchart"
import BarCharts from "../AllCharts/chartjs/barchart"
import VerticalComposedChart from "../AllCharts/rechart/VerticalComposedChart"
import MixBarChart from "../AllCharts/rechart/MixBarChart"
import mcpService from "services/mcp.service"

const Dashboard = props => {
  const [pos_company, setPosCompany] = useState([])
  const [cash_vendor, setCashVendor] = useState("")

  useEffect(async () => {
    const posuud = await mcpService.getPos()
    setPosCompany(posuud.data)
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Суваг дэлгүүрийн Dashboard</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs
            title="Tables"
            breadcrumbItem="Суваг дэлгүүрийн Dashboard"
          />
          <Container fluid>
            <Row className="mb-3">
              <label
                htmlFor="example-search-input"
                className="col-md-2 col-form-label"
              >
                Кассын систем
              </label>
              <div className="col-md-10">
                <select
                  className="form-control"
                  type="text"
                  value={cash_vendor}
                  onChange={e => {
                    setCashVendor(e.target.value)
                  }}
                >
                  <option value="">Бүгд</option>
                  {pos_company.map(param => {
                    return (
                      <option
                        key={param._id}
                        name={param.name}
                        value={param._id}
                      >
                        {param.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            </Row>
            <Row>
              <Col lg={4}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Үйл ажиллагаа</CardTitle>
                    <DonutChart pos_company={cash_vendor} />
                  </CardBody>
                </Card>
              </Col>
              <Col lg={4}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Ажиллагааны статус</CardTitle>
                    <BarChart pos_company={cash_vendor} />
                  </CardBody>
                </Card>
              </Col>
              <Col lg={4}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Bar Chart</CardTitle>

                    <BarCharts pos_company={cash_vendor} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl={3}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Дэлгүүрийн зэрэглэл</CardTitle>
                    <VerticalComposedChart pos_company={cash_vendor} />
                  </CardBody>
                </Card>
              </Col>
              <Col xl={3}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Дэлгүүрийн зэрэглэл</CardTitle>
                    <VerticalComposedChart pos_company={cash_vendor} />
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Дэлгүүрийн хаяг</CardTitle>
                    <MixBarChart pos_company={cash_vendor} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <iframe
                  src="https://www.google.com/maps/d/u/0/embed?mid=1zu8LKUwvyLDk13IfeS1F38mo3I7W0jh7"
                  width="100%"
                  height="400"
                ></iframe>
              </Col>
            </Row>
            <Row>
            <Col lg={3}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Bar Chart</CardTitle>

                    <BarCharts pos_company={cash_vendor} />
                  </CardBody>
                </Card>
              </Col></Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Dashboard

import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { getPos, getShop, createShop } from "../../services/mcp.service"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Button,
  Form,
  Label,
  Input,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const addShop = props => {
  const [pos, setPos] = useState([])
  const [merchant, setMerchant] = useState([])
  const [regNumber, setReg] = useState([])
  const [shopName, setShopName] = useState([])
  const [posCreate, setPosCreate] = useState("5f4361361e030228fd9f7b02")
  const [status, setStatus] = useState([])
  const [cash_vendor, setCashVendor] = useState(1)

  useEffect(async () => {
    try {
      const pos = await getPos()
      console.log(pos.data, "pos")
      setPos(pos.data)
    } catch (err) {
      console.log(err)
    }
  }, [])
  console.log(posCreate, "ghsagjkhasdg")
  const addShop = async () => {
    const registrationData = {
      company_name: merchant,
      name: shopName,
      registration_number: regNumber,
      cash_vendor: posCreate,
      status: status,
      pos_number: cash_vendor,
    }

    try {
      const result = await createShop(registrationData)
      if (result.status === "error") throw result
      else toastr.success("Амжилттай")
      window.location.reload()
      console.log(result)
    } catch (err) {
      toastr.error(err.message)
      console.log(err, "errror")
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Дэлгүүр үүсгэх</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="Form" breadcrumbItem="Дэлгүүр үүсгэх" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  {/* <Form> */}
                  <Row className="mb-3">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-2 col-form-label"
                    >
                      Байгууллагын нэр
                    </label>
                    <div className="col-md-10">
                      <input
                        className="form-control"
                        type="text"
                        value={merchant}
                        onChange={e => {
                          setMerchant(e.target.value)
                        }}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-2 col-form-label"
                    >
                      Регистрийн дугаар
                    </label>
                    <div className="col-md-10">
                      <input
                        className="form-control"
                        type="text"
                        value={regNumber}
                        onChange={e => {
                          setReg(e.target.value)
                        }}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-2 col-form-label"
                    >
                      Дэлгүүрийн нэр
                    </label>
                    <div className="col-md-10">
                      <input
                        className="form-control"
                        type="text"
                        value={shopName}
                        onChange={e => {
                          setShopName(e.target.value)
                        }}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="example-search-input"
                      className="col-md-2 col-form-label"
                    >
                      Кассын систем
                    </label>
                    <div className="col-md-10">
                      <select
                        className="form-control"
                        type="text"
                        value={posCreate}
                        onChange={e => {
                          setPosCreate(e.target.value)
                        }}
                      >
                        {pos.map(param => {
                          return (
                            <option
                              key={param._id}
                              name={param.name}
                              value={param._id}
                            >
                              {param.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="example-search-input"
                      className="col-md-2 col-form-label"
                    >
                      Төлөв
                    </label>
                    <div className="col-md-10">
                      <select
                        className="form-control"
                        type="text"
                        value={status}
                        onChange={e => {
                          setStatus(e.target.value)
                        }}
                      >
                        <option value="-1">Ноорог</option>
                        <option value="1">Нээлттэй</option>
                        <option value="0">Хаагдсан</option>
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="example-search-input"
                      className="col-md-2 col-form-label"
                    >
                      Кассын тоо
                    </label>
                    <div className="col-md-10">
                      <select
                        className="form-control"
                        type="text"
                        value={cash_vendor}
                        onChange={e => {
                          setCashVendor(e.target.value)
                        }}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                    </div>

                    <div className="row justify-content-end">
                      <Col sm={10}>
                        <div style={{ marginTop: "2%", marginLeft: "0.5%" }}>
                          <Button
                            type="submit"
                            color="primary"
                            className="w-md"
                            onClick={addShop}
                          >
                            Үүсгэх
                          </Button>
                        </div>
                      </Col>
                    </div>
                  </Row>
                  {/* </Form> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default addShop

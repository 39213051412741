import axios from "axios"
// import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
// const token = accessToken

//apply base url for axios
const API_URL = "http://10.10.10.123:3040"
// const API_URL = "http://192.168.10.37:3040"

import { authService } from "services/auth.service"

// axiosApi.defaults.headers.common["Authorization"] = token

// axiosApi.interceptors.response.use(
//   response => response,
//   error => Promise.reject(error)
// )

export async function get(url, config = {}) {
  return await axios
    .get(API_URL + url, { ...config })
    .then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axios
    .post(API_URL + url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axios
    .put(API_URL + url, { ...data }, { ...config })
    .then(response => response.data)
}
export async function patch(url, data, config = {}) {
  return axios
    .patch(API_URL + url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axios
    .delete(API_URL + url, { ...config })
    .then(response => response.data)
}

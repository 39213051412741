import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Shop from "../pages/Shop/index"
import Info from "../pages/Info/index"
import Logs from "../pages/Logs/index"
import Report from "../pages/Report/index"
import addShop from '../pages/Shop/addShop'

// Dashboard
import Dashboard from "../pages/Dashboard/index"

const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/addShop", component:  addShop},

  // //profile
  { path: "/profile", component: UserProfile },
  { path: "/shop", component: Shop },
  { path: "/info/:merchant/:id", component: Info },
  { path: "/report", component: Report },
  { path: "/logs", component: Logs },

  { path: "/", component: () => <Redirect to="/login" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { userRoutes, authRoutes }

import React from "react"
import { Bar } from "react-chartjs-2"

const BarChart = () => {
  const data = {
    labels: ["Идэвхтэй", "Баримт хэвлэсэн"],
    datasets: [
      {
        // label: "",
        backgroundColor: ['rgba(153, 102, 255, 0.2)', 'rgba(75, 192, 192, 0.2)'],
        borderColor: "rgba(52, 195, 143, 0.8)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(52, 195, 143, 0.9)",
        hoverBorderColor: "rgba(52, 195, 143, 0.9)",
        data: [4, 4],
      },
    ],
  }

  const option = {
    scales: {
      dataset: [
        {
          barPercentage: 0.4,
        },
      ],
    },
  }

  return <Bar width={474} height={300} data={data} options={option} />
}

export default BarChart

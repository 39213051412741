import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { getDashboard } from "services/mcp.service"

const barchart = ({pos_company}) => {
  const [series, setSeries] = useState([])
  const [categories, setCategories] = useState([])
  useEffect(async () => {
    const datas = await getDashboard("work_status", pos_company)
    console.log(datas, "datas")
    setSeries([
      {
        data: 
          datas.map(data => {
            return data.count
          }),
        
      },
    ])
    setCategories(
      datas.map(data => {
        return data.name
      })
    )
  }, [pos_company])
  
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },

    colors: ["#15D3EE", "#34c38f"],
    grid: {
      borderColor: "#34c38f",
    },
    xaxis: {
      categories: categories,
    },
  }

  return (
    <ReactApexChart options={options} series={series} type="bar" height="335" />
  )
}

export default barchart

import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
  Table,
  Input,
  Modal,
} from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import classnames from "classnames"
import { updateBranch, createCare } from "services/mcp.service"
import Select from "react-select"
import moment from "moment"

const optionGroup = [
  {
    options: [
      { label: "Lightbox", value: "Lightbox" },
      { label: "Metal", value: "Metal" },
    ],
  },
]
const TabTwo = ({ branch, setBranch }) => {
  const [activeTab, toggleTab] = useState("1")
  const [careType, setCareType] = useState("Care call")
  const [solType, setSolType] = useState("")
  const [note, setNote] = useState("")
  const [isEdit, setIsEdit] = useState(false)
  const [modal_branch, setmodal_branch] = useState(false)
  const [selectedMulti, setselectedMulti] = useState(null)

  const toggle = tab => {
    if (activeTab !== tab) toggleTab(tab)
  }
  function tog_branch() {
    setmodal_branch(!modal_branch)
    // removeBodyCss()
  }
  const handleChange = e => {
    const key = e.target.name
    const value = e.target.value
    setBranch({
      ...branch,
      [key]: value,
    })
  }
  useEffect(() => {
    setCareType(branch?.care?.care_type)
    setSolType(branch?.care?.solution_type)
    setNote(branch?.care?.description)
    setselectedMulti(branch?.address_type)
  }, [])

  function handleMulti(selectedMulti) {
    setselectedMulti(branch?.address_type)
  }

  const saveChanges = () => {
    updateBranch(branch?.merchant?._id, branch?._id, branch)
    setIsEdit(false)
  }
  const addCare = async () => {
    const careData = {
      care_type: careType,
      solution_type: solType,
      description: note,
    }
    try {
      const createdCare = await createCare(
        branch?.merchant?._id,
        branch?._id,
        careData
      )
      if (createdCare.status === "error") throw createdCare
      else toastr.success("Амжилттай")
      window.location.reload()
    } catch (err) {
      toastr.error(err.message)
      console.log(err.message, "errror")
    }
  }

  return (
    <React.Fragment>
      <Row className="justify-content-center">
        <Col xl={8}>
          <div>
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <CardTitle>Тохижилттой холбоотой : </CardTitle>
                    <Row className="align-items-center">
                      {isEdit === true ? (
                        <div>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Хаяг тавигдсан эсэх:
                            </label>
                            <div className="col-md-10" style={{ width: "30%" }}>
                              <select
                                className="form-control"
                                type="text"
                                name="does_have_address"
                                value={branch?.does_have_address}
                                onChange={handleChange}
                              >
                                <option value={false}>Үгүй</option>
                                <option value={true}>Тийм</option>
                              </select>
                            </div>
                          </Row>

                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Хаяг тавьсан огноо:
                            </label>
                            <div className="col-md-10" style={{ width: "30%" }}>
                              <input
                                className="form-control"
                                name={"address_date"}
                                type="date"
                                value={branch.address_date}
                                onChange={handleChange}
                              />
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Хаяг төрөл
                            </label>
                            <div className="col-md-10" style={{ width: "30%" }}>
                              {/* <input
                                name={"address_type"}
                                className="form-control"
                                value={branch.address_type}
                                onChange={handleChange}
                              /> */}
                              <Select
                                value={branch.address_type}
                                isMulti={false}
                                name="address_type"
                                onChange={handleMulti}
                                options={optionGroup}
                                classNamePrefix="select2-selection"
                              />
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Бусад тохижилт:
                            </label>
                            <div className="col-md-10" style={{ width: "30%" }}>
                              <input
                                className="form-control"
                                name={"other_decorations"}
                                value={branch.other_decorations}
                                onChange={handleChange}
                              />
                            </div>
                          </Row>
                        </div>
                      ) : (
                        <Row>
                          <Col>
                            <div>
                              <h6>
                                Хаяг тавигдсан эсэх:{" "}
                                {branch?.does_have_address ? "Тийм" : "Үгүй"}
                              </h6>
                              <h6>
                                Хаяг тавьсан огноо: {branch?.address_date}
                              </h6>
                              <h6>Хаяг төрөл: {branch?.address_type}</h6>
                            </div>
                          </Col>
                          <Col>
                            <div>
                              <h6>
                                Бусад тохижилт: {branch?.other_decorations}
                              </h6>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </Row>
                    <Row>
                      <Col md={11}></Col>
                      <Col md={1}>
                        {isEdit === false ? (
                          <Button
                            onClick={() => {
                              setIsEdit(true)
                            }}
                          >
                            Засах
                          </Button>
                        ) : (
                          <Button onClick={saveChanges}>Save</Button>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Card>
          <CardBody>
            <CardTitle>Care тэмдэглэл</CardTitle>
            <Row className="align-items-center">
              <Col>
                <div className="table-responsive">
                  <Col>
                    <Button
                      color="info"
                      className="btn btn-info "
                      style={{ marginLeft: "1250px" }}
                      onClick={tog_branch}
                    >
                      Тэмдэглэл нэмэх
                    </Button>
                    <Modal
                      isOpen={modal_branch}
                      toggle={() => {
                        tog_branch()
                      }}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                          Тохижилт нэмэх
                        </h5>
                        <button
                          type="button"
                          onClick={() => {
                            setmodal_branch(false)
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <Row className="mb-3">
                          <label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Care төрөл
                          </label>
                          <div className="col-md-10">
                            {/* <input
                              className="form-control"
                              type="text"
                              value={careType}
                              onChange={e => {
                                setCareType(e.target.value)
                              }}
                            /> */}
                            <select
                              className="form-control"
                              type="text"
                              value={careType}
                              onChange={e => {
                                setCareType(e.target.value)
                              }}
                            >
                              <option value="Care call">Care call</option>
                              <option value="Техник тусламж">
                                Техник тусламж
                              </option>
                              <option value="Хөдөлгөөнт care">
                                Хөдөлгөөнт care
                              </option>
                            </select>
                          </div>
                        </Row>

                        <Row className="mb-3">
                          <label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Шийдсэн төрөл
                          </label>
                          <div className="col-md-10">
                            <input
                              className="form-control"
                              type="text"
                              value={solType}
                              onChange={e => {
                                setSolType(e.target.value)
                              }}
                            />
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Тэмдэглэл
                          </label>
                          <div className="col-md-10">
                            <Input
                              className="form-control"
                              type="textarea"
                              value={note}
                              onChange={e => {
                                setNote(e.target.value)
                              }}
                            />
                          </div>
                        </Row>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={addCare}
                        >
                          Нэмэх
                        </button>
                      </div>
                    </Modal>
                  </Col>
                  <Table className="align-middle mb-0">
                    <thead>
                      <tr>
                        <th>Огноо</th>
                        <th>Care төрөл</th>
                        <th>Шийдсэн төрөл</th>
                        <th>Тэмдэглэл</th>
                        <th>Тэмдэглэл нэмсэн</th>
                      </tr>
                    </thead>
                    <tbody>
                      {branch?.care?.map((care, id) => {
                        return (
                          <tr key={id}>
                            <th>{moment(care.created_at).format("L")}</th>
                            <td>{care.care_type}</td>
                            <td>{care.solution_type}</td>
                            <td style={{ width: "200" }}>{care.description}</td>
                            <td>{care.username}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Row>
    </React.Fragment>
  )
}

export default TabTwo

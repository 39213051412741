import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { getDashboard} from "../../../services/mcp.service"



const dountchart = ({pos_company}) => {
  const [series, setSeries] = useState([])
  const [labels, setLabels] = useState([])
  useEffect( async() => {
    const result = await getDashboard('status', pos_company)
    setSeries(result.map(res => {
      return res.count
    }))
    setLabels(result.map(res => {
      return res.name
    }))
  }, [pos_company])
  // const series = [44, 55, 41, 17, 15]
  const options = {
    labels: labels,
    colors: ["#556ee6", "#f46a6a", "#34c38f"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="donut"
      height="380"
    />
  )
}

export default dountchart

import React, { useEffect, useState } from "react"
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Area,
  ResponsiveContainer,
} from "recharts"
import { getDashboard } from "services/mcp.service"

export default function VerticalComposedChart({pos_company}) {
  const [data, setSeries] = useState([])
  //   const [categories, setCategories] = useState([])
  useEffect(async () => {
    const datas = await getDashboard("qrs.shop_tier", pos_company)
    setSeries(datas)
    // setCategories(
    //   datas.map(data => {
    //     return data.name
    //   })
    // )
  }, [pos_company])
  return (
    <div style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
        <ComposedChart
          layout="vertical"
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis type="number" />
          <YAxis dataKey="name" type="category" scale="band" />
          <Tooltip />
          <Legend />
          {/* <Area dataKey="count" fill="#8884d8" stroke="#8884d8" /> */}
          <Bar dataKey="count" barSize={20} fill="#413ea0" />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}
